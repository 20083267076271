interface FormData {
  sellingPrice: string;
  productCost: string;
  length: string;
  width: string;
  height: string;
  weight: string;
}

interface Errors {
  [key: string]: string;
}

export const validateInputs = (formData: FormData): Errors => {
  const errors: Errors = {};

  const numericFields = ['sellingPrice', 'productCost', 'length', 'width', 'height', 'weight'];

  numericFields.forEach(field => {
    const value = parseFloat(formData[field as keyof FormData]);
    if (isNaN(value) || value <= 0) {
      errors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} must be a positive number.`;
    }
  });

  return errors;
};
