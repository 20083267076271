import React, { forwardRef } from 'react';
import styled from 'styled-components';

const ResultsContainer = styled.div`
  margin-top: 20px;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
`;

const ResultItem = styled.div`
  margin-bottom: 10px;
`;

interface ResultsDisplayProps {
  results: {
    sizeTier: string;
    fbaFulfillmentFee: number;
    monthlyStorageFee: number;
    grossRevenue: number;
    totalCosts: number;
    contributionMargin: number;
    contributionMarginPercentage: number;
  };
}

const ResultsDisplay = forwardRef<HTMLDivElement, ResultsDisplayProps>(({ results }, ref) => {
  return (
    <ResultsContainer ref={ref}>
      <h2>Results</h2>
      <ResultItem>
        <strong>Size Tier:</strong> {results.sizeTier}
      </ResultItem>
      <ResultItem>
        <strong>FBA Fulfillment Fee:</strong> ${results.fbaFulfillmentFee.toFixed(2)}
      </ResultItem>
      <ResultItem>
        <strong>Monthly Storage Fee:</strong> ${results.monthlyStorageFee.toFixed(2)}
      </ResultItem>
      <ResultItem>
        <strong>Gross Revenue:</strong> ${results.grossRevenue.toFixed(2)}
      </ResultItem>
      <ResultItem>
        <strong>Total Costs:</strong> ${results.totalCosts.toFixed(2)}
      </ResultItem>
      <ResultItem>
        <strong>Contribution Margin:</strong> ${results.contributionMargin.toFixed(2)}
      </ResultItem>
      <ResultItem>
        <strong>Contribution Margin Percentage:</strong> {results.contributionMarginPercentage.toFixed(2)}%
      </ResultItem>
    </ResultsContainer>
  );
});

export default ResultsDisplay;
