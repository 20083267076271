import React, { useState } from 'react';
import styled from 'styled-components';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

const Input = styled.input`
  padding: 5px;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px;
  font-size: 16px;
  background-color: #3B2BA3;
  color: white;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #2E2282;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const Notification = styled.div<{ success?: boolean }>`
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: ${props => props.success ? '#3B2BA3' : '#f44336'};
  color: white;
`;

interface EmailFormProps {
  onSubmit: (email: string) => void;
  emailSent: boolean | null;
  notificationMessage: string;
}

const EmailForm: React.FC<EmailFormProps> = ({ onSubmit, emailSent, notificationMessage }) => {
  const [email, setEmail] = useState('');
  const [consent, setConsent] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (consent) {
      onSubmit(email);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter your email"
        required
        disabled={emailSent === true}
      />
      <label>
        <Checkbox
          type="checkbox"
          checked={consent}
          onChange={(e) => setConsent(e.target.checked)}
          disabled={emailSent === true}
        />
        I acknowledge that I can be added to the newsletter and contacted for follow-up.
      </label>
      <Button type="submit" disabled={!consent || emailSent === true}>
        {emailSent === true ? 'Email Sent' : 'Send Results'}
      </Button>
      {notificationMessage && (
        <Notification success={emailSent === true}>
          {notificationMessage}
        </Notification>
      )}
    </Form>
  );
};

export default EmailForm;
