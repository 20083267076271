import React, { useState } from 'react';
import styled from 'styled-components';
import { validateInputs } from '../utils/validators';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
`;

const Input = styled.input`
  padding: 5px;
  font-size: 16px;
`;

const Select = styled.select`
  padding: 5px;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px;
  font-size: 16px;
  background-color: #3B2BA3;
  color: white;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #2E2282;
  }
`;

interface InputFormProps {
  onSubmit: (formData: any) => void;
}

const InputForm: React.FC<InputFormProps> = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    sellingPrice: '',
    productCost: '',
    length: '',
    width: '',
    height: '',
    weight: '',
    storageType: 'Standard',
    season: 'Non-peak',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const errors = validateInputs(formData);
    if (Object.keys(errors).length === 0) {
      onSubmit(formData);
    } else {
      alert(Object.values(errors).join('\n'));
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputGroup>
        <Label htmlFor="sellingPrice">Selling Price</Label>
        <Input
          id="sellingPrice"
          type="number"
          name="sellingPrice"
          value={formData.sellingPrice}
          onChange={handleChange}
          step="0.01"
          required
        />
      </InputGroup>
      <InputGroup>
        <Label htmlFor="productCost">Product Cost</Label>
        <Input
          id="productCost"
          type="number"
          name="productCost"
          value={formData.productCost}
          onChange={handleChange}
          step="0.01"
          required
        />
      </InputGroup>
      <InputGroup>
        <Label htmlFor="length">Length (inches)</Label>
        <Input
          id="length"
          type="number"
          name="length"
          value={formData.length}
          onChange={handleChange}
          step="0.01"
          required
        />
      </InputGroup>
      <InputGroup>
        <Label htmlFor="width">Width (inches)</Label>
        <Input
          id="width"
          type="number"
          name="width"
          value={formData.width}
          onChange={handleChange}
          step="0.01"
          required
        />
      </InputGroup>
      <InputGroup>
        <Label htmlFor="height">Height (inches)</Label>
        <Input
          id="height"
          type="number"
          name="height"
          value={formData.height}
          onChange={handleChange}
          step="0.01"
          required
        />
      </InputGroup>
      <InputGroup>
        <Label htmlFor="weight">Weight (pounds)</Label>
        <Input
          id="weight"
          type="number"
          name="weight"
          value={formData.weight}
          onChange={handleChange}
          step="0.01"
          required
        />
      </InputGroup>
      <InputGroup>
        <Label htmlFor="storageType">Storage Type</Label>
        <Select id="storageType" name="storageType" value={formData.storageType} onChange={handleChange}>
          <option value="Standard">Standard</option>
          <option value="Long-term">Long-term</option>
        </Select>
      </InputGroup>
      <InputGroup>
        <Label htmlFor="season">Season</Label>
        <Select id="season" name="season" value={formData.season} onChange={handleChange}>
          <option value="Non-peak">Non-peak (Jan-Sep)</option>
          <option value="Peak">Peak (Oct-Dec)</option>
        </Select>
      </InputGroup>
      <Button type="submit">Calculate</Button>
    </Form>
  );
};

export default InputForm;
