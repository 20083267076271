import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import InputForm from './components/InputForm';
import ResultsDisplay from './components/ResultsDisplay';
import EmailForm from './components/EmailForm';
import { GlobalStyle } from './styles';

const AppContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Notification = styled.div<{ success?: boolean }>`
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: ${props => props.success ? '#3B2BA3' : '#f44336'};
  color: white;
`;

const App: React.FC = () => {
  const [results, setResults] = useState<any | null>(null);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [emailSent, setEmailSent] = useState<boolean | null>(null);
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const [originalInputs, setOriginalInputs] = useState<any | null>(null);
  const resultsRef = useRef<HTMLDivElement>(null);

  const handleCalculate = async (formData: any) => {
    try {
      const response = await fetch('/api/calculate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      setResults(data);
      setOriginalInputs(formData);
      setShowEmailForm(true);
      
      // Scroll to results after state update
      setTimeout(() => {
        resultsRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    } catch (error) {
      console.error('Error calculating fees:', error);
      setNotificationMessage('Error calculating fees. Please try again.');
      setEmailSent(false);
    }
  };

  const handleEmailSubmit = async (email: string) => {
    try {
      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, results, originalInputs }),
      });
      if (response.ok) {
        setEmailSent(true);
        setNotificationMessage('Results sent to your email!');
      } else {
        throw new Error('Failed to send email');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      setEmailSent(false);
      setNotificationMessage('Failed to send email. Please try again.');
    }
  };

  return (
    <AppContainer>
      <GlobalStyle />
      <h1>Prima Mode: Amazon FBA Cost Calculator</h1>
      <InputForm onSubmit={handleCalculate} />
      {showEmailForm && (
        <EmailForm 
          onSubmit={handleEmailSubmit} 
          emailSent={emailSent} 
          notificationMessage={notificationMessage}
        />
      )}
      {results && <ResultsDisplay results={results} ref={resultsRef} />}
    </AppContainer>
  );
};

export default App;
